<div class="mpb-container">

    <div class="home-loader" [id]="'isLoading'" *ngIf="isLoading">
        <div class="spinner-border spinner-border-sm"></div>
    </div>
    <!-- [infiniteScrollDisabled]="false"  true-->
    <div infiniteScroll [infiniteScrollDistance]="10" [infiniteScrollThrottle]="10" [infiniteScrollUpDistance]="2.5" (scrolled)="scrollMore()" [scrollWindow]="true" [alwaysCallback]="true" [immediateCheck]="true">
        <ng-container #mainContainer></ng-container>
    </div>
</div>
<!-- <div id="notfoundPopup" class=" notfoundPopup modal modal-custom" role="dialog">
    <h1 style="margin: auto;width: 50%;color: white;">
        Please Add Data in ContentFul
    </h1>
</div> -->

<div class="notfoundPopup modal" tabindex="-1" role="dialog" id="notfoundPopup">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-center">Data Not Found !</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>PageCode or PageSlug for the Category is Not Added in Contentful!</p>
          
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-primary">Save changes</button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div> -->
      </div>
    </div>
  </div>
