import {
  Component, OnInit, ViewEncapsulation, ViewContainerRef, ComponentFactoryResolver, ChangeDetectorRef, Injector, Inject, ViewChild, Output, EventEmitter, ElementRef, Renderer2, HostListener, PLATFORM_ID
  ,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Entry } from 'contentful';
import { Subscription } from 'rxjs';
import { ContentfulService } from 'src/app/services/contentful.service';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import { LoadDynamicComponentService } from 'src/app/services/loaddynamiccomponent.service';
import * as global from 'src/app/globals';
import { PriceService } from 'src/app/services/price.service';
import { take } from 'rxjs/operators';
import { ReplaySubject, Observable } from 'rxjs';
import { isPlatformServer, PlatformLocation } from "@angular/common";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicPopupsModalComponent } from 'src/app/account/dynamic-popups-modal/dynamic-popups-modal.component';
import { ComponentCanDeactivate } from 'src/app/guard/auth-guard.service';
declare var $: any;
import { environment } from 'src/environments/environment';
import { ExternalService } from 'src/app/services/external.service';
import { RegisterComponent } from 'src/app/user/register/register.component';
@Component({
    selector: 'app-categoryhome',
    templateUrl: './categoryhome.component.html',
    styleUrls: ['./categoryhome.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class CategoryhomeComponent implements OnInit, ComponentCanDeactivate {
  pageFooter: any = [];
  noOfTimes: any;
  noOfTimesFooter: number = 0;
  isAPrerender: boolean;
  counter: number = 0;
  productName: string;
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // //
    return true;
  }
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  // define private class properties
  data: Entry<any>[] = [];
  headerDataSubscription: Subscription;
  @Output() seoMetaData: EventEmitter<any> = new EventEmitter();
  @ViewChild('scroller') private scroller: ElementRef;
  arrFields = [];
  componentRef: any;
  isLoading: Boolean = true;
  categoryResponse: any;
  pageResponse;
  allInOnePageSections = [];
  entryInfo;
  arrBannerData = [];
  arrProduct3Set = []; destroy
  contentType: string = 'page';
  totalComponentToLoad: any;
  scrollCount: any;
  urlName: any;
  subcategorySlug: any;
  screenHeight: any;
  windowWidth: any;
  viewType: any;
  componentEntryId: any;
  @ViewChild('mainContainer', { read: ViewContainerRef, static: true }) maincontentContainer: ViewContainerRef;
  scrollEnabled: boolean = false;
  emitFooter: Boolean = true;
  slugWithoutSubcategory: any;
  firstRouteSlug: string;
  scrollToProductConfigurator: boolean = false;
  languageData: any;
  languageDataSubscription: Subscription;
  partnerCode: any;
  fromReferal: any;
  partnerUrl: any = {}
  dicUrls: any;
  //
  listOfloadedComponent = [];

  @HostListener('window:resize', ['$event'])
  getDeviceScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.windowWidth = window.innerWidth;

    //  
  }
  @HostListener('document:click', ['$event'])
  clickOutside(event) {
    const clickedElement = event.target as HTMLElement;
    //  



    // Check if the clicked element is an anchor element
    if (clickedElement.tagName === 'A' && clickedElement.classList && !event.target.classList.contains("externalLinkCful")) {
      const anchorElement = clickedElement as HTMLAnchorElement;
      if (anchorElement.href) {
        let navigateToUrl = anchorElement.href
        let linkForComponent = navigateToUrl.split('?id=')[1];
        if (linkForComponent) {
          const componentId = linkForComponent.split('&item=')[0];
          this.contentfulService.emitComponentId(componentId)
        }



      }

    } else {
      if (event.target.classList) {
        if (event.target.classList.contains("externalLinkCful")) {

          if (event.target.classList[1]) {
            window.open(event.target.classList[1], '_blank');
          }
        }
        else if (event.target.classList[0]) {
          if (event.target.classList[0].split('externalLinkCful')[1]) {
            const redirectedUrl = event.target.classList[0].split('externalLinkCful')[1]

            if (redirectedUrl != '') {
              window.open(redirectedUrl, '_blank');
            }

          }


        }

      }
    }




  }
  scrollcheckOnceTopSlider: Boolean = false;
  scrollcheckOncePageContentSlider: Boolean = false;
  constructor(private contentfulService: ContentfulService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private dataService: DataService,
    private globalService: GlobalService,
    private sanitizer: DomSanitizer,
    private resolver: ComponentFactoryResolver,
    private changeDetect: ChangeDetectorRef,
    private injector: Injector,
    // private app: ApplicationRef,
    private loaddynamicComponentService: LoadDynamicComponentService,
    private viewContainerRef: ViewContainerRef,
    private priceService: PriceService,
    private elRef: ElementRef, private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    locations: PlatformLocation,
    private readonly svc: ExternalService,

  ) {
    ////


    renderer.listen(elRef.nativeElement, 'scroll', (event) => {

      event.stopPropagation();
      event.preventDefault();
    })
    this.getDeviceScreenSize();

    this.contentfulService.$componentToLoadOnDemand.subscribe(data => {
      if (data) {
        if (data == 'cful-product-configurator-component') {
          this.scrollToProductConfigurator = true;
        } else {
          // 
          this.scrollToComponentInSamePage(data);
          this.scrollToProductConfigurator = false;
        }

      }
    });


    this.contentfulService.$showLoader.subscribe(data => {
      this.isLoading = data;
      // //
    });
    locations.onPopState(() => {
      //  
      let currentUrl = this.router.url
      if (this.router && currentUrl && (currentUrl.includes('create/easy') || currentUrl.includes('app/product') || currentUrl.includes('fotoabzuege/bestellen') || currentUrl.includes('photo-prints/order') || currentUrl.includes("myphotos") || currentUrl.includes("myphotos")
      )
      ) {
        location.reload()

      }


      this.maincontentContainer.clear();


    });

  }

  ngOnInit() {
    var _this = this;
    this.noOfTimes = 0;
    // location.reload();
    //  

    this.windowWidth = window.innerWidth;
    this.viewType = (this.windowWidth > 575) ? 'Desktop' : 'Mobile';
    // alert(window.innerWidth)

    this.slugWithoutSubcategory = ['register-confirmation', 'reset-password', 'updateemail', 'qrcode']
    window.scrollTo(0, 0);
    this.scrollCount = 0;



    this.activeRoute.paramMap.subscribe((params: ParamMap) => {
      this.noOfTimes = 0;
      this.counter = 0;
      this.scrollCount = 0;
      this.noOfTimesFooter = 0;
      let footerObject = {
        footerVersion: false,
        footerData: false
      }
      this.contentfulService.emitFooterData(footerObject)

      //this.contentfulService.emitFooterAtLast(false);
      //  this.loadFooter();
      this.maincontentContainer.clear();
      let componentId = this.activeRoute.snapshot.queryParamMap.get('id');
      this.componentEntryId = (componentId) ? componentId : '';
      // 
      let url = this.router.url.split('?')[0];
      let arrUrl = url.split('/');
      if (arrUrl[1] == 'logout-url') {
        this.logout();
      }
      this.subcategorySlug = '';
      this.subcategorySlug = params.get('subcategory');
      this.urlName = (arrUrl[1] == '') ? 'home' : arrUrl[1];

      // 

      if (url == '/') {
        this.loadData("home");
      }
      else {
        var categoryUrl = decodeURIComponent(arrUrl[1]);

        var headerData;
        if (this.globalService.getHeaderData() != undefined) {
          headerData = this.globalService.getHeaderData();
          // 
          if (this.router.url != '/') {

            if (this.counter == 0) {
              this.counter++
              this.start(categoryUrl, headerData);
            }

          }
        }
        else {

          this.headerDataSubscription = this.globalService.headerDataEmiter.subscribe(data => {
            if (data) {
              headerData = data;
              if (this.router.url != '/') {
                if (this.counter == 0) {
                  this.counter++
                  this.start(categoryUrl, headerData);
                }
                // this.start(categoryUrl, headerData);
              }

            }
          });





          // 
        }
      }
      if (categoryUrl == 'referral') {
        this.fromReferal = decodeURIComponent(arrUrl[2]);
      } else if (categoryUrl == 'partnerreferral') {
        this.fromReferal = decodeURIComponent(arrUrl[2]);
      }


    });

    if (this.globalService.gettLanguageData() != undefined) {
      this.languageData = this.globalService.gettLanguageData();
      this.changeDetect.detectChanges()
    }
    else {
      this.languageDataSubscription = this.globalService.languageDataEmiter.subscribe(data => {
        if (data) {
          this.languageData = data;
          this.changeDetect.detectChanges()
        }
      });
    }
  }



  start(categoryUrl, headerData) {


    // 

    //  
    // localStorage.setItem('alreadyTriggred', 'Yes')
    window.scrollTo(0, 0);
    var pageSlug;
    let categoryData = headerData.filter(x => x.categoryUrl === categoryUrl)[0];
    let slugWithoutSubcategoryIndex = this.slugWithoutSubcategory.indexOf(categoryUrl);
    let isSubCategorySlug = (slugWithoutSubcategoryIndex >= 0) ? false : true;

    if (categoryData) {
      pageSlug = categoryData.categoryUrl;
    }
    else {

      pageSlug = categoryUrl;
    }
    if (this.activeRoute.snapshot.params['code'] && isSubCategorySlug) {
      pageSlug = pageSlug + "/" + this.activeRoute.snapshot.params['code'];
    }
    else if (this.activeRoute.snapshot.params['level1']) {
      pageSlug = pageSlug + "/" + this.activeRoute.snapshot.params['level1'];
    }
    if (this.activeRoute.snapshot.params['level2'] && this.urlName != 'crm') {
      pageSlug = pageSlug + "/" + this.activeRoute.snapshot.params['level2'];
    }
    if (this.activeRoute.snapshot.params['level3'] && this.urlName != 'crm') {
      pageSlug = pageSlug + "/" + this.activeRoute.snapshot.params['level3'];
    }
    if (this.activeRoute.snapshot.params['level4'] && this.urlName != 'crm') {
      pageSlug = pageSlug + "/" + this.activeRoute.snapshot.params['level4'];
    }

    //(urlcheck.includes("myphotos") || urlcheck.includes("myphotos"))
    //
    const code = this.activeRoute.snapshot.params['code'];
    //

    let parseURL = location.href.split("/");
    let env = window['environmentType'];
    let oemIndex = parseURL.indexOf(env);
    // //

    if (categoryUrl == 'register-confirmation') {

      if (this.noOfTimes == 0) {
        this.dataService.emailConfirmation(code).subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          this.noOfTimes++;
          // //
          this.isLoading = false;

          if (resJSON.success == true) {

            this.loadData(pageSlug);
            return false;
          } else {

            resJSON['verificationcode'] = code;
            resJSON['updateemail'] = false;
            resJSON['email'] = (localStorage.getItem('userInfo') == undefined || localStorage.getItem('userInfo') == null) ? '' : JSON.parse(localStorage.getItem('userInfo'))['Email'];
            ////
            if (resJSON.code == '5') {
              this.contentfulService.openemailVerificationModal(resJSON)

            } else {

              this.router.navigateByUrl(('/'));
              // this.router.navigate(['/'])
              //       .then(() => {

              //         window.location.reload();
              //         ////

              //       });

            }

          }
        })
      }



    }
    else if (categoryUrl == 'qrcode') {
      this.dataService.updateQRcode(code).subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        this.isLoading = false;

        //  //
        if (resJSON.success == true) {
          this.router.navigateByUrl(('/'));
        }


      })

    }
    else if (categoryUrl == 'reset-password') {
      // this.contentfulService.openemailResetPasswordModal({ code })

      const data = { code, fromemail: true };
      this.contentfulService.openemailResetPasswordModal(data)
      // this.router.navigateByUrl(('/'));
      this.loadData(pageSlug);
    }
    else if (categoryUrl == 'referral') {
      localStorage.setItem('fromReferal', this.fromReferal);
      localStorage.setItem('referalType', 'User');
      if (this.globalService.gettLanguageData() != undefined) {
        this.languageData = this.globalService.gettLanguageData();
        this.customerReferal();
      }
      else {
        this.languageDataSubscription = this.globalService.languageDataEmiter.subscribe(data => {
          if (data) {
            this.languageData = data;
            this.customerReferal();
          }
        });
      }


    }
    else if (categoryUrl == 'partnerreferral') {
      this.dataService.getPartnerReferral(this.fromReferal).subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        this.isLoading = false;
        if (resJSON.success == true) {
          let partnerrferralLink = resJSON.data
          if (partnerrferralLink != '') {
            localStorage.setItem('fromReferal', this.fromReferal);
            localStorage.setItem('referalType', 'Partner');
            window.location.href = partnerrferralLink.pageUrl
            this.router.navigate([window.location.href]);
          } else {
            localStorage.setItem('fromReferal', this.fromReferal);
            localStorage.setItem('referalType', 'Partner');
            let title = this.languageData.web.partner_referal_popup_title;
            let subTitle = this.languageData.web.partner_referal_popup_msg;
            let okBtn = this.languageData.web.partner_referal_popup_cta;
            const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
            modalRef.componentInstance.data = {
              title: title, subTitle: subTitle, okBtnTxt: okBtn, modalName: 'partnerReferalUrl', cancelIcon: false,
            };
            modalRef.componentInstance.partnerReferal.subscribe((res) => {
              if (res) {
                this.router.navigateByUrl(('/'));
              }
            })
          }
        }
        else {
          localStorage.removeItem("fromReferal");
          this.router.navigateByUrl(('/'));
        }
      })

    }

    else if (categoryUrl == 'updateemail') {

      if (this.noOfTimes == 0) {
        this.dataService.setUpdateUserEmail(code, 'Approve').subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          this.isLoading = false;
          this.noOfTimes++;
          if (resJSON.success == true && resJSON.code == '1') {
            // 
            this.loadData(pageSlug);
          } else {

            resJSON['verificationcode'] = code;
            resJSON['updateemail'] = true;
            resJSON['email'] = (localStorage.getItem('userInfo') == undefined || localStorage.getItem('userInfo') == null) ? '' : JSON.parse(localStorage.getItem('userInfo'))['Email'];
            ////
            if (resJSON.code == '2' || resJSON.code == '3' || resJSON.code == '4' || resJSON.code == '5') {
              this.contentfulService.openemailVerificationModal(resJSON)

            }

          }
        })
      }



    }
    else if (categoryUrl == 'share-subscribe' || categoryUrl == 'teilen-abonnieren') {

      this.dataService.subscribephotoalbum(code).subscribe((res: Response) => {
        this.isLoading = false;
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);

        let url = this.dicUrls['PHOTOS_PREVIEW'];
        if (resJSON.success == true) {

          //  this.router.navigateByUrl(url);
          localStorage.clear()
          setTimeout(() => {

            const modalRef = this.modalService.open(RegisterComponent, {
              size: 'md',
              windowClass: 'modalWindowWidth',
              centered: true,
              backdrop: 'static',
              keyboard: false,
            });
            modalRef.result.then((result) => { }).catch((error) => { });
            modalRef.componentInstance.productdata = {
              redirectToctaLink: url
            };




          }, 1500);

          //   this.router.navigate([url]);


          // this.sharedAlbumData.status='Accepted';
          // this.getSharedAlbumDetails(this.sharedAlbumData,this.selectedAlbumIndex)
        }
        else {
          //  
          this.router.navigateByUrl(('/'));
          //  localStorage.clear()
          // setTimeout(() => {
          //   this.router.navigate([url]);
          //   const modalRef = this.modalService.open(RegisterComponent, {
          //     size: 'md',
          //     windowClass: 'modalWindowWidth',
          //     centered: true,
          //     backdrop: 'static',
          //     keyboard: false,
          //   });
          //   modalRef.result.then((result) => { }).catch((error) => { });
          //   modalRef.componentInstance.productdata = {
          //     redirectToctaLink: url
          //   };




          // }, 1500);



        }


      })

    }
    else if (categoryUrl == 'project-share-subscribe' || categoryUrl == 'projekt-teilen-abonnieren') {

      this.dataService.subscribesharedProject(code).subscribe((res: Response) => {
        this.isLoading = false;
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
          let url = 'preview/' + resJSON.data.oemProduct + '/' + resJSON.data.albumCode
          //this.dicUrls['PROJECT_SHARE'];
          //  this.router.navigateByUrl(url);

          //let
          this.router.navigate([url]);
          // this.sharedAlbumData.status='Accepted';
          // this.getSharedAlbumDetails(this.sharedAlbumData,this.selectedAlbumIndex)
        }
        else {
          this.router.navigateByUrl(('/'));
        }
      })

    }

    else if (categoryUrl == 'advertising-unsubscribe') {
      let data = {
        type: 'advertising-unsubscribe',
        code: code
      }
      let requestPayload = {
        oemCode: environment[global.env].oemCode,
        code: code,
        langCode: global.lang
      }

      if (this.noOfTimes == 0) {
        this.dataService.edmUnsubscribe(requestPayload).subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          this.noOfTimes++;
          if (resJSON.success == true) {
            data['message'] = resJSON.message;
            data['apiReturnSuccess'] = true;
            data['actionOkText'] == this.languageData.web.edm_unsubscribe_popup_success_cta;
            data['email'] = resJSON.email;
            //  this.contentfulService.openCommonModal(data)

            this.loadData(categoryUrl);
            return false;
          }
          else {
            data['apiReturnSuccess'] = false;
            data['messaage'] = resJSON.message;
            data['email'] = resJSON.email;
            //this.contentfulService.openCommonModal(data)

            this.router.navigateByUrl(('/'));
          }
        })

      }




    }

    else if ((categoryUrl == 'newsletter')) {
      let currentUrl = pageSlug.split('/')

      if (currentUrl.length == 1) {
        this.loadData(categoryUrl);
        return
      }
      let contentfulSlug;

      let code;
      if (currentUrl[1] == 'confirmed') {
        contentfulSlug = currentUrl[0] + '/' + currentUrl[1]
        code = currentUrl[2];
      } else {
        code = currentUrl[1];
        contentfulSlug = currentUrl[0];
      }
      if (code == undefined || code == '') {
        this.router.navigateByUrl(('/'));
        return;
      }

      if (this.noOfTimes == 0) {
        this.dataService.newsletterUnsubscribe('', 'subscribe', '', '', '', code).subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          this.noOfTimes++
          if (resJSON.success == true) {
            this.loadData(contentfulSlug);
            return false;

            // this.contentfulService.openCommonModal(data)
          }
          else {
            // this.contentfulService.openCommonModal(data)

            this.router.navigateByUrl(('/'));
          }
        })

      }





    }

    else if ((categoryUrl == 'newsletter-unsubscribe')) {
      let data = {
        type: 'newsletter-unsubscribe',
        code: code
      }
      //  
      if (this.noOfTimes == 0) {
        this.dataService.newsletterUnsubscribe('', 'unsubscribe', '', '', '', code).subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          this.noOfTimes++;
          if (resJSON.success == true) {
            data['message'] = resJSON.message;
            data['email'] = resJSON.email;
            data['apiReturnSuccess'] = true;

            this.loadData(categoryUrl);
            return false;

            // this.contentfulService.openCommonModal(data)
          }
          else {
            data['message'] = resJSON.message;
            data['email'] = resJSON.email;
            data['apiReturnSuccess'] = false;
            // this.contentfulService.openCommonModal(data)


            this.router.navigateByUrl(('/'));
          }
        })

      }





    }

    else if (categoryUrl == 'newsletter-verification') {
      let data = {
        type: 'newsletter-verification',
        code: code
      }
      if (this.noOfTimes == 0) {
        this.dataService.newsletterUnsubscribe('', 'subscribe', '', '', '', code).subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          this.noOfTimes++;
          if (resJSON.success == true) {
            data['message'] = resJSON.message;
            // data['email']=resJSON.email;
            data['apiReturnSuccess'] = true;

            this.loadData(categoryUrl);
            return false;
          }
          else {

            this.router.navigateByUrl(('/'));
          }
        })

      }





    }
    else {

      let urlTree: any = this.router.parseUrl(this.router.url)
      urlTree.queryParams = {};
      urlTree.fragment = null;
      urlTree = urlTree.toString() // option
      // 
      // currentUrl.map(item=>{
      //   urlString=+item+'/';
      // })
      let routeArrayToMatch = urlTree.substring(1).split('/');
      this.firstRouteSlug = routeArrayToMatch[0]
      let matchedFromMenuJson = [];
      matchedFromMenuJson.push(routeArrayToMatch[0]);
      let slugIsProductType = false;
      // 
      // console.log('routeArrayToMatch',routeArrayToMatch);
      
      if (routeArrayToMatch.length > 1) {
        slugIsProductType = this.recursiveFnForTraversingMenuJson(categoryData, routeArrayToMatch, 0, matchedFromMenuJson)
        //  
      }
      else {
        this.contentfulService.emitproductCode('')
      }
      //
      this.loadData(pageSlug, slugIsProductType);
    }



  }

  recursiveFnForTraversingMenuJson(node, routeArrayToMatch, recursionNo, matchedFromMenuJson) {
    recursionNo++;
    //  //
    if (node && node.subcategories) {
      let data = node.subcategories.filter(x => x.categoryUrl === routeArrayToMatch[recursionNo])[0];
      const dataisPresent = (data == undefined || data == '') ? false : true;
      if (recursionNo === routeArrayToMatch.indexOf(routeArrayToMatch[recursionNo])) {
        matchedFromMenuJson.push(routeArrayToMatch[recursionNo])
        this.recursiveFnForTraversingMenuJson(data, routeArrayToMatch, recursionNo, matchedFromMenuJson)

      }
    }
    else if (node && node.categoryProduct) {
      let data = node.categoryProduct.filter(x => x.URL === routeArrayToMatch[recursionNo])[0];
      const dataisPresent = (data == undefined || data == '') ? false : true;
      if (dataisPresent) {
        this.contentfulService.emitproductCode(data.productCode)
        matchedFromMenuJson.push(routeArrayToMatch[recursionNo]);
      }
      else{
       let isNoMatch = false;
       this.contentfulService.emitproductCode(isNoMatch);
      }

    }
    return JSON.stringify(matchedFromMenuJson) == JSON.stringify(routeArrayToMatch);

  }
  callproductSeoMeta(pageSlug) {
    
    this.contentfulService.getContentByFieldName('seoMetadata', pageSlug).then(data => {
      if (data) {
        if (data.length == 0) {
          //   
          this.loadData(this.firstRouteSlug)

        }
        else {
          // 
          const slugIsProductType = false;
          const setSeo = true;
          let seoData = {};
          if (data[0].fields) {
            seoData = data[0].fields;
            // this.globalService.setSeoMetaData(seoData);
          }

          this.loadData(this.firstRouteSlug, slugIsProductType, setSeo)

        }
      }
    })

  }

  loadData(pageSlug, slugIsProductType = false, setSeo = true) {
    console.info(pageSlug)
    // 
    this.contentfulService.getContentByFieldName(this.contentType, pageSlug).then(data => {
      this.isLoading = false;
      let isOfflineApp:any;
      this.maincontentContainer.clear();
      this.activeRoute.queryParams.subscribe(params => {
        let offlineAppParam = params['offlineApp'];
        if (offlineAppParam) {
          isOfflineApp = true;
        }
     
      });
      if (data) {
        // 
        $('#notfoundPopup').hide();
        if (data.length == 0) {
          let routerUrl = this.router.url.split('?')
          // console.log('routerUrl',routerUrl[0]);
          // this.productName = routerUrl[0].replace(/^\//, ''); 
          if (isOfflineApp) {
            $('#notfoundPopup').show();
          }
          if (slugIsProductType || routerUrl.length > 1) {
            this.callproductSeoMeta(pageSlug);
            return;

          } else {
            let routerUrl = this.router.url.split('?')
            //  
            if (routerUrl.length > 1) {

            } else {
              this.router.navigateByUrl('/404');
              return;

            }

          }   
        }

        else if (data[0] && (data[0].fields['topSection'] || data[0].fields['pageContent'])) {

          this.allInOnePageSections = [];
          if (data[0] && data[0].fields['topSection'] != undefined) {
            let datas = []
            data[0].fields['topSection'].map(item => {
              if (item.fields) {
                datas.push(item)
              }
            });
            let topSection = datas;
            topSection.map((item, index) => {

              if (item.fields) {
                if (data[0].fields['pageCode'] == 'APP') {
                  item.fields.onlyForApp = true
                }
                let displayComponent = true;
                if (item.fields.doNotDisplayOn) {
                  if (item.fields.doNotDisplayOn.findIndex(item => item == environment[global.env].oemCode) > -1) {
                    displayComponent = false
                  } else {
                    displayComponent = true
                  }
                }

                if (
                  displayComponent && (
                    (this.viewType == 'Mobile' && (item.fields.displayOn == undefined || item.fields.displayOn == 'Desktop and Mobile' || item.fields.displayOn == 'Mobile'))
                    ||
                    (this.viewType == 'Desktop' && (item.fields.displayOn == undefined || item.fields.displayOn == 'Desktop and Mobile' || item.fields.displayOn == 'Desktop'))
                  )) {
                  if (item.fields.heroBannerDesignVersion == 'Gallery-1-full-v1') {
                    item.fields.sliderSection = 'topSection';
                  }
                  if (item.fields.selectDesign == undefined) {
                    //  //
                    this.allInOnePageSections.push(item);
                  }


                }


              }

              // 

            })


          }
          else {
            data[0].fields['topSection'] = [];
          }
          if (data[0] && data[0].fields['pageContent'] != undefined) {
            let datas = []
            //  
            data[0].fields['pageContent'].map(item => {
              if (item.fields) {
                if (data[0].fields['pageCode'] == 'APP') {
                  item.fields.onlyForApp = true
                }
                datas.push(item)
              }
            });

            let pageContent = datas;
            pageContent.map((item, index) => {
              if (item.fields) {

                let displayComponent = true;
                if (item.fields.doNotDisplayOn) {
                  if (item.fields.doNotDisplayOn.findIndex(item => item == environment[global.env].oemCode) > -1) {
                    displayComponent = false
                  } else {
                    displayComponent = true
                  }
                }

                if (displayComponent && (
                  (this.viewType == 'Mobile' && (item.fields.displayOn == undefined || item.fields.displayOn == 'Desktop and Mobile' || item.fields.displayOn == 'Mobile'))
                  ||
                  (this.viewType == 'Desktop' && (item.fields.displayOn == undefined || item.fields.displayOn == 'Desktop and Mobile' || item.fields.displayOn == 'Desktop'))
                )) {
                  if (item.fields.heroBannerDesignVersion == 'Gallery-1-full-v1') {
                    item.fields.sliderSection = 'pageContentSection';
                  }
                  this.allInOnePageSections.push(item);

                }


              }

            })

          }
          else {
            data[0].fields['pageContent'] = [];
          }
          //  alert(this.viewType)

          //  

          this.prepareToLoad(data, slugIsProductType, setSeo);


        }


      } else {
        //  this.router.navigateByUrl('/404')
      }


    })


    // setTimeout(() => {
    //   $("body").addClass('overflow-hidden');
    // }, 500);

    // setTimeout(() => {
    //   $("body").removeClass('overflow-hidden');
    // }, 4500);


  }
  prepareToLoad(data, slugIsProductType, setSeo) {
    //  
    if (data.length > 0 && data[0].fields['seoMetadata'] && setSeo) {
      let seoData = {};
      seoData = data[0].fields['seoMetadata'].fields;
      this.globalService.setSeoMetaData(seoData);

      this.contentfulService.emittranslatedUrl(data[0].fields['seoMetadata'].sys.id);


    }
    if (this.allInOnePageSections) {
      this.allInOnePageSections.map((item, index) => {
        //  
        const templateVersion = item.fields.heroBannerDesignVersion || item.fields.txtBlockSelectDesignTemplate || item.fields.imageBoxCarouselDesignVersion || item.fields.bodyTxtSelectDesign || item.fields.testimonialBlockDesign || item.fields.templateCode || item.fields.sliderDesignVersion
        if (this.scrollToProductConfigurator && item.fields.templateCode == 'product_selector') {
          this.componentEntryId = item.sys.id;
        }

        if (this.loaddynamicComponentService.templateWiseComponents[templateVersion] == undefined) {
          
          this.allInOnePageSections.splice(index, 1)
        }



      })
      let headerObject = {
        headerVersion: false,
        headerData: false
      }
      ////
      if (data[0].fields['topSection']) {
        let allComponentFilter = JSON.parse(JSON.stringify(data[0].fields['topSection']))
        let headerFound = allComponentFilter.filter(item => item.fields && item.fields.selectDesign);


        if (headerFound.length > 0) {
          let headerData = headerFound[0];

          if (headerData.fields.widget) {
            for (var i = 0; i < headerData.fields.widget.length; i++) {
              if (headerData.fields.widget[i].fields == undefined) {
                headerData.fields.widget.splice(i, 1)
                i--;
              }
            }
          }

          // 
          if (headerData.fields.selectDesign) {
            let headerObject = {
              headerVersion: headerData.fields.selectDesign.split('-')[2],
              headerData
            }

            // if(headerData.fields.widget && localStorage.getItem('header-widget-closed')=='Yes'){
            // delete  headerData.fields.widget;
            // }
            // localStorage.getItem()
            this.contentfulService.emitHeaderData(headerObject)
            this.loadAfterHeader(headerObject, data)

          }
        } else {
          this.contentfulService.emitHeaderData(headerObject);
          this.loadAfterHeader(headerObject, data)
        }


      } else {
        this.contentfulService.emitHeaderData(headerObject)
        this.loadAfterHeader(headerObject, data)
      }

      if (data[0].fields['pageFooter']) {
        this.pageFooter = data[0].fields['pageFooter']
      }




    }


    // setTimeout(() => {
    //   this.maincontentContainer.clear();
    //   if (data.length > 0) {

    //   }
    // }, 1000);


  }

  loadAfterHeader(headerObject, data) {
    this.maincontentContainer.clear();
    this.isLoading = true
    // 
    if (headerObject.headerData != false) {
      // 
      var timer = setInterval(() => {
        // 
        let headerId = (document.getElementById('cd-header') || document.getElementById('cd-small-header'))
        if (headerId != null) {
          // 
          // this.pageResponse = data[0].fields;
          this.callprepareToLoad(data)
          clearInterval(timer);
        } else {
          this.maincontentContainer.clear();
        }
        // clearInterval(timer);

      }, 1000);
    } else {
      //
      this.callprepareToLoad(data)

    }

  }
  callprepareToLoad(data) {

    this.pageResponse = data[0].fields;
    if (this.pageResponse['topSection'] != '' || this.pageResponse['pageContent'] != '') {
      setTimeout(() => {
        this.isLoading = false
        this.prepareContent();
      }, 1);



    }
  }

  recursiveFnForRemovalOfEmptyPTag(node) {
    if (node.content) {
      var contentLength = node.content.length;
      var i = 1
      for (var key in node.content) {
        if (i == contentLength && node.content[key].nodeType == 'paragraph') {
          let blankParaLength = node.content[key].content.filter(x => x.value == '').length;
          if (node.content[key].content.length == blankParaLength) {
            node.content.splice(i - 1, 1);
          }
        }
        i++;
        if (node.content[key]) {
          this.recursiveFnForRemovalOfEmptyPTag(node.content[key]);

        }
      }
    }
    // else{

    // }
  }
  findNodeElement(node) {
    let i = 0;
    // //
    if (node) {
      for (let key = 0; i < node.length; key++) {

        // for (var key in node) {


        if (typeof (node[key]) === 'object') {

          if (node[key].sys != undefined && node[key].fields == undefined) {

            for (let indexval = 0; indexval < node.length; indexval++) {
              if (node[indexval].sys != undefined && node[indexval].fields == undefined) {
                node.splice(indexval, 1)
                indexval--;
              }

            }

          }

          if (node[key]) {
            for (var innerKey in node[key].fields) {

              // 

              if (node[key] && node[key].fields && node[key].fields[innerKey] && Array.isArray(node[key].fields[innerKey])) {

                for (let indexval = 0; indexval < node[key].fields[innerKey].length; indexval++) {
                  if (node[key].fields[innerKey][indexval].sys != undefined && node[key].fields[innerKey][indexval].fields == undefined) {
                    //  
                    node[key].fields[innerKey].splice(indexval, 1)
                    indexval--;
                  }

                }


              }

              if (node[key] && node[key].fields && node[key].fields[innerKey] && node[key].fields[innerKey].nodeType) {
                this.recursiveFnForRemovalOfEmptyPTag(node[key].fields[innerKey])
                //  
              }
              else {
                // 
                if (node[key] && node[key].fields && node[key].fields['doNotDisplayOn'] != undefined) {

                  let displayComponent = true;
                  if (node[key].fields['doNotDisplayOn']) {
                    //  

                    // this.removeEntries(node,key)
                    if (node[key] && node[key].fields['doNotDisplayOn']) {
                      this.removeEntries(node, key)

                    } else {
                      this.findNodeElement(node[key].fields[innerKey])
                    }
                  }
                }

              }
            }
          }


        }
        i++;
      }
    }

  }

  removeEntries(data, index) {

    for (var i = 0; i < data.length; i++) {
      if (data[i] && data[i].fields && data[i].fields['doNotDisplayOn'] && data[i].fields['doNotDisplayOn'].findIndex(item => item == environment[global.env].oemCode) > -1) {

        data.splice(i, 1)
        i--;

      }
    }

  }

  prepareContent() {
    // 

    this.allInOnePageSections.map((item, index) => {
      if (item.fields) {

        for (var key in item.fields) {

          if (typeof (item.fields[key]) === 'object') {

            if (item.fields[key].nodeType) {
              this.recursiveFnForRemovalOfEmptyPTag(item.fields[key])
            }
            else {
              //
              this.findNodeElement(item.fields[key])
            }

          } else {
            if (this.urlName == 'crm') {
              if (typeof (item.fields[key]) === 'string') {
                //  item.fields[key]=item.fields[key]+'%d'
                item.fields[key] = this.contentfulService.replaceText(item.fields[key])

              }

            }

          }

        }

      }
    });

    var isPrerender = navigator.userAgent.toLowerCase().indexOf('prerender') !== -1;
    this.isAPrerender = isPrerender;
    if (isPrerender) {
      
    }

    if (isPrerender) {
      // -> Server rendered
      // alert('test')
      // this.totalComponentToLoad = this.allInOnePageSections.length;
      // this.scrollCount = 0;
      // let topSectionSlider = this.allInOnePageSections.filter(x => x.fields.sliderSection == 'topSection');
      // let pageContentSectionSlider = this.allInOnePageSections.filter(x => x.fields.sliderSection == 'pageContentSection');
      // this.allInOnePageSections.map((item, index) => {
      //   if (!this.allInOnePageSections[index].fields.loaded) {
      //     
      //     let isBannerSlider = false;
      //     if (item.fields.sliderSection == 'topSection' || this.allInOnePageSections[this.scrollCount].fields.sliderSection == 'pageContentSection') {
      //       if (item.fields.sliderSection == 'topSection' && !this.scrollcheckOnceTopSlider) {
      //         isBannerSlider = true;
      //         this.scrollcheckOnceTopSlider = true;
      //         this.allInOnePageSections.map(items => {
      //           if (items.fields.sliderSection === 'topSection') {
      //             items.fields.loaded = true;
      //           }
      //         })
      //         this.fetchFieldsComponentWise(topSectionSlider, isBannerSlider);
      //       }
      //       if (item.fields.sliderSection == 'pageContentSection' && !this.scrollcheckOncePageContentSlider) {
      //         isBannerSlider = true;
      //         this.scrollcheckOncePageContentSlider = true;

      //         this.allInOnePageSections.map(items => {
      //           if (items.fields.sliderSection === 'pageContentSection') {
      //             items.fields.loaded = true;
      //           }
      //         })

      //         this.fetchFieldsComponentWise(pageContentSectionSlider, isBannerSlider);

      //       }

      //     }
      //     else {
      //       isBannerSlider = false;
      //       item.fields.loaded = true;
      //       this.fetchFieldsComponentWise(item, isBannerSlider);
      //     }

      //   }

      // })
      // setTimeout(() => {
      //   this.contentfulService.emitFooterAtLast(true);
      //   this.loadFooter();
      // }, 1000);

      // //


      let isBannerSlider = false;
      let componentHeight = 0;
      this.scrollCount = 0;
      let topSectionSlider = [];
      let pageContentSectionSlider = [];
      this.totalComponentToLoad = this.allInOnePageSections.length;

      const topbannerSliderIndex = this.allInOnePageSections.findIndex(item => item.fields.sliderSection == 'topSection');
      const pageContentbannerSliderIndex = this.allInOnePageSections.findIndex(item => item.fields.sliderSection == 'pageContentSection');
      this.scrollEnabled = false;
      var scrollcheckOnceTopSlider = false;
      var scrollcheckOncePageContentSlider = false;
      var sliderIndex = this.allInOnePageSections.findIndex(item => item.fields.sliderDesignVersion);
      // 
      for (let i = 0; i < this.allInOnePageSections.length; i++) {
        var item = this.allInOnePageSections[i];


        if (item.fields.sliderSection === 'topSection' || item.fields.sliderSection === 'pageContentSection') {
          if (item.fields.sliderSection === 'topSection' && topbannerSliderIndex >= 0 && !scrollcheckOnceTopSlider) {
            scrollcheckOnceTopSlider = true;
            isBannerSlider = true;
            topSectionSlider = this.allInOnePageSections.filter(x => x.fields.sliderSection == 'topSection')
            this.allInOnePageSections.map(items => {
              if (items.fields.sliderSection === 'topSection') {
                items.fields.loaded = true;
              }
            })
            this.callDelay(sliderIndex, i, topSectionSlider, isBannerSlider)


          }
          else if (item.fields.sliderSection === 'pageContentSection' && pageContentbannerSliderIndex >= 0 && !scrollcheckOncePageContentSlider) {

            isBannerSlider = true;
            scrollcheckOncePageContentSlider = true;
            pageContentSectionSlider = this.allInOnePageSections.filter(x => x.fields.sliderSection == 'pageContentSection')
            this.allInOnePageSections.map(items => {
              if (items.fields.sliderSection === 'pageContentSection') {
                items.fields.loaded = true;
              }

            })

            this.callDelay(sliderIndex, i, pageContentSectionSlider, isBannerSlider)

          }
        } else {




          this.callDelay(sliderIndex, i, item, false)
        }
      }
      this.contentfulService.emitFooterAtLast(true);
      this.loadFooter();
    }
    else {
      // -> Browser rendered
      // 
      this.contentfulService.emitFooterAtLast(false);
      let isBannerSlider = false;
      let componentHeight = 0;
      this.scrollCount = 0;
      let topSectionSlider = [];
      let pageContentSectionSlider = [];
      this.totalComponentToLoad = this.allInOnePageSections.length;

      const topbannerSliderIndex = this.allInOnePageSections.findIndex(item => item.fields.sliderSection == 'topSection');
      const pageContentbannerSliderIndex = this.allInOnePageSections.findIndex(item => item.fields.sliderSection == 'pageContentSection');
      this.scrollEnabled = false;
      var scrollcheckOnceTopSlider = false;
      var scrollcheckOncePageContentSlider = false;
      var sliderIndex = this.allInOnePageSections.findIndex(item => item.fields.sliderDesignVersion);
      // this.allInOnePageSections[sliderIndex].fields.loaded=true
      // 
      for (let i = 0; i < this.allInOnePageSections.length; i++) {
        var item = this.allInOnePageSections[i];


        if (item.fields.sliderSection === 'topSection' || item.fields.sliderSection === 'pageContentSection') {
          if (item.fields.sliderSection === 'topSection' && topbannerSliderIndex >= 0 && !scrollcheckOnceTopSlider) {
            scrollcheckOnceTopSlider = true;
            isBannerSlider = true;
            topSectionSlider = this.allInOnePageSections.filter(x => x.fields.sliderSection == 'topSection')
            this.allInOnePageSections.map(items => {
              if (items.fields.sliderSection === 'topSection') {
                items.fields.loaded = true;
              }
            })

            this.callDelay(sliderIndex, i, topSectionSlider, isBannerSlider)

          }
          else if (item.fields.sliderSection === 'pageContentSection' && pageContentbannerSliderIndex >= 0 && !scrollcheckOncePageContentSlider) {

            isBannerSlider = true;
            scrollcheckOncePageContentSlider = true;
            pageContentSectionSlider = this.allInOnePageSections.filter(x => x.fields.sliderSection == 'pageContentSection')
            this.allInOnePageSections.map(items => {
              if (items.fields.sliderSection === 'pageContentSection') {
                items.fields.loaded = true;
              }

            })

            this.callDelay(sliderIndex, i, pageContentSectionSlider, isBannerSlider)


          }
        } else {




          if (this.componentEntryId != '') {
            if (this.componentEntryId == item.sys.id) {

              let nextComponentHeight = this.allInOnePageSections[i].fields.approxheight;;
              componentHeight += nextComponentHeight
              item.fields.loaded = true;

              this.callDelay(sliderIndex, i, item, false)




            } else {


              item.fields.loaded = true;

              this.callDelay(sliderIndex, i, item, false)

            }
          } else {

            item.fields.loaded = true;
            this.callDelay(sliderIndex, i, item, false)

          }
        }
      }

    }
  }

  callDelay(sliderIndex, i, item, value) {
    // 
    if (sliderIndex < 0 || sliderIndex > 0) {
      this.fetchFieldsComponentWise(item, value);
    }
    else if (sliderIndex == i) {
      this.fetchFieldsComponentWise(item, value);
    } else if (sliderIndex >= 0 && i > sliderIndex) {
      setTimeout(() => {
        this.fetchFieldsComponentWise(item, value);
      }, 1000);
    }
    // this.listOfloadedComponent.push(item.sys.id)
  }

  scrollMore() {

    if (this.isAPrerender) {
      return false;
    }
    let topSectionSlider = [];
    let pageContentSectionSlider = [];


    // let indexNotLoaded = this.allInOnePageSections.findIndex(x => x.fields.loaded == undefined || x.fields.loaded == false);
    // this.scrollCount = indexNotLoaded;
    // if (indexNotLoaded > -1) {
    //   if (!this.allInOnePageSections[this.scrollCount].fields.loaded) {
    //     let isBannerSlider = false;
    //     if (this.allInOnePageSections[this.scrollCount].fields.sliderSection == 'topSection' || this.allInOnePageSections[this.scrollCount].fields.sliderSection == 'pageContentSection') {
    //       if (this.allInOnePageSections[this.scrollCount].fields.sliderSection == 'topSection' && !this.scrollcheckOnceTopSlider) {
    //         isBannerSlider = true;
    //         this.scrollcheckOnceTopSlider = true;
    //         topSectionSlider = this.allInOnePageSections.filter(x => x.fields.sliderSection == 'topSection')
    //         this.scrollCount++;
    //         this.allInOnePageSections.map(items => {
    //           if (items.fields.sliderSection === 'topSection') {
    //             items.fields.loaded = true;
    //           }
    //         })
    //       //  this.fetchFieldsComponentWise(topSectionSlider, isBannerSlider);
    //       }
    //       if (this.allInOnePageSections[this.scrollCount].fields.sliderSection == 'pageContentSection' && !this.scrollcheckOncePageContentSlider) {
    //         isBannerSlider = true;
    //         this.scrollcheckOncePageContentSlider = true;
    //         pageContentSectionSlider = this.allInOnePageSections.filter(x => x.fields.sliderSection == 'pageContentSection');
    //         this.allInOnePageSections.map(items => {
    //           if (items.fields.sliderSection === 'pageContentSection') {
    //             items.fields.loaded = true;
    //           }
    //         })

    //        // this.fetchFieldsComponentWise(pageContentSectionSlider, isBannerSlider);
    //         this.scrollCount++;
    //       }

    //     }
    //     else {
    //       isBannerSlider = false;
    //       this.allInOnePageSections[this.scrollCount].fields.loaded = true;
    //     //  this.fetchFieldsComponentWise(this.allInOnePageSections[this.scrollCount], isBannerSlider);
    //     }

    //   }


    // }


    let loadedComponents = this.allInOnePageSections.filter(x => x.fields.loaded === true);
    //

    if (this.allInOnePageSections.length == loadedComponents.length && this.scrollCount == loadedComponents.length) {
      //
      if (this.emitFooter) {
        //  this.emitFooter = false;
        this.contentfulService.emitFooterAtLast(true);
        if (this.noOfTimesFooter == 0) {
          this.loadFooter();
        }

      }
    }

  }

  loadFooter() {
    let footerObject = {
      footerVersion: false,
      footerData: false
    }

    if (this.pageFooter != '') {

      let allComponentFilter = JSON.parse(JSON.stringify(this.pageFooter))
      let footerFound = allComponentFilter.filter(item => item.fields && item.fields.selectDesign);


      if (footerFound.length > 0) {
        let footerData = footerFound[0];
        if (footerData.fields.selectDesign) {
          let footerObject = {
            footerVersion: footerData.fields.selectDesign.split('-')[2],
            footerData
          }

          if (footerObject.footerData.fields.widget) {
            footerObject.footerData.fields.widget.map(item => {
              if (item.loaded != true) {
                this.fetchFieldsComponentWise(item, false);

              }

              item.loaded = true
            });
            this.noOfTimesFooter++;
          }
          this.contentfulService.emitFooterData(footerObject)

        }
      } else {
        this.contentfulService.emitFooterData(footerObject)
      }


    } else {
      this.contentfulService.emitFooterData(footerObject)
    }
  }

  scrollToComponentInSamePage(componentEntryId) {
    this.componentEntryId = componentEntryId;

    let el = document.getElementById(componentEntryId);
    if (el == null) {
      this.allInOnePageSections.map(item => {

        this.scrollMore()
      })


    } else {
      setTimeout(() => {
        let el = document.getElementById(componentEntryId);
        if (el != null) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start',

          });
        }

      }, 1000);

    }



  }
  fetchFieldsComponentWise(item, isBannerSlider) {

    if (isBannerSlider) {
      const templateVersion = 'Gallery-1-full-v1';
      this.loaddynamicComponentService.loadComponentInstance(templateVersion, item, this.maincontentContainer, this.componentEntryId)


    } else {


      const templateVersion = item.fields.heroBannerDesignVersion || item.fields.txtBlockSelectDesignTemplate || item.fields.imageBoxCarouselDesignVersion || item.fields.bodyTxtSelectDesign || item.fields.testimonialBlockDesign || item.fields.templateCode || item.fields.sliderDesignVersion

      if (templateVersion) {
        item = JSON.parse(JSON.stringify(item))
        this.loaddynamicComponentService.loadComponentInstance(templateVersion, item, this.maincontentContainer, this.componentEntryId)
      }
      if (item.fields.imageBoxCarouselDesignVersion == 'product-2-set-v1' ||
        item.fields.imageBoxCarouselDesignVersion == 'Grid-2-v1' ||
        item.fields.imageBoxCarouselDesignVersion == 'Grid-4-v1' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-2-02' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-2-03' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-3-01' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-3-02' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-3-03' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-2-01' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-1-01-L' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-1-01-R' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-1-02-R' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-1-02-L' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-2-04' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-2-05' ||
        item.fields.imageBoxCarouselDesignVersion == 'EL-Grid-4-01') {
        for (let i = 0; i < item.fields.imageBoxes.length; i++) {
          if ((item.fields.imageBoxes[i].fields && item.fields.imageBoxes[i].fields.imageVariableInfo) || (item.fields.imageBoxes[i].fields && item.fields.imageBoxes[i].fields.imageStaticInfo1)) {
            this.arrProduct3Set.push(item.fields.imageBoxes[i].fields);
          }
          if (item.fields.imageBoxes[i].fields) {
            item.fields.imageBoxes[i].fields.promoBox = false;
          }

        }
        if (this.arrProduct3Set.length > 0) {
          this.calculatePrice();
        }
      }


    }

    this.scrollCount++;
    let loadedComponents = this.allInOnePageSections.filter(x => x.fields.loaded === true);
    //

    if (this.allInOnePageSections.length == loadedComponents.length && this.scrollCount == loadedComponents.length) {
      //
      if (this.emitFooter) {
        //  this.emitFooter = false;
        this.contentfulService.emitFooterAtLast(true);
        if (this.noOfTimesFooter == 0) {
          this.loadFooter();
        }

      }
    }
  }

  calculatePrice() {
    var productHash = [];

    for (let i = 0; i < this.arrProduct3Set.length; i++) {
      if (this.arrProduct3Set[i].imageStaticInfo1) {
        this.arrProduct3Set[i].finalPrice = this.arrProduct3Set[i].imageStaticInfo1;
      }
      else if (this.arrProduct3Set[i].imageVariableInfo && this.arrProduct3Set[i].finalPrice == undefined) {
        let productCode = this.arrProduct3Set[i].imageVariableInfo;
        productHash.push(
          {
            "productCode": productCode,
            "quantity": 1
          }
        );
      }
    }

    if (productHash.length > 0) {
      this.dataService.pricelist(productHash).subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          //  

          for (var i = 0; i < this.arrProduct3Set.length; i++) {
            if (!this.arrProduct3Set[i].imageStaticInfo1 && this.arrProduct3Set[i].imageVariableInfo) {
              let productCode = this.arrProduct3Set[i].imageVariableInfo;
              this.arrProduct3Set[i].priceDefault = resJSON.data.productInfo[productCode];
              if (resJSON.data.productInfo[productCode] != undefined) {
                let minPage = resJSON.data.productInfo[productCode].productDetail.minPage;

                let price_range = resJSON.data.productInfo[productCode].price_range
                this.showPromoAndDefaultPrice(this.arrProduct3Set[i].priceDefault, minPage, this.arrProduct3Set[i], price_range);
              }
            }
          }
        }
      }, (error) => {
        
      });
    }
  }

  showPromoAndDefaultPrice(priceDefault, pageCount, productInfo, price_range) {

    productInfo.finalPrice = this.priceService.priceCalculator(priceDefault, pageCount, pageCount, true, 0, 0, 0, 0, 1, undefined);
    if (priceDefault && priceDefault.productDetail && priceDefault.productDetail.swfFile) {
      productInfo.swfFile=priceDefault.productDetail.swfFile
    }


    if (priceDefault.promoInfo == null) {
      productInfo.promoPrice = false;
    }
    else {
      productInfo.promoPrice = this.priceService.priceCalculator(priceDefault.promoInfo, pageCount, pageCount, true, 0, 0, 0, 0, 1, undefined);
      if (productInfo.finalPrice == productInfo.promoPrice) {
        productInfo.promoPrice = false;
      }




    }
    //  
    if (productInfo.imageAltStaticInfo2) {
      productInfo.finalPrice = productInfo.finalPrice;
    }
    //

    if (this.globalService.gettLanguageData() != undefined) {
      this.languageData = this.globalService.gettLanguageData();
      if (price_range && price_range != '' && price_range == 'Yes') {
        productInfo.imageAltStaticInfo2 = this.languageData.web.content_price_range;
      } else {
        productInfo.imageAltStaticInfo2 = productInfo.imageAltStaticInfo2;
      }
    }
    else {
      this.languageDataSubscription = this.globalService.languageDataEmiter.subscribe(data => {
        if (data) {
          this.languageData = data;
          if (price_range && price_range != '' && price_range == 'Yes') {
            productInfo.imageAltStaticInfo2 = this.languageData.web.content_price_range;
          } else {
            productInfo.imageAltStaticInfo2 = productInfo.imageAltStaticInfo2;
          }
        }
      });
    }



  }

  // @HostListener("window:scroll", ['$event'])
  // onWindowScroll(e) {
  //   
  // }


  logout() {
    // localStorage.removeAll(localStorage.clear());
    localStorage.clear()
    // let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.router.navigate(['/']);
    this.globalService.setCartLengthData(0);
    localStorage.setItem('reload', 'true');
  }

  customerReferal() {
    let title, subTitle, okBtn;
    title = this.languageData.web.customer_referal_popup_title;
    subTitle = this.languageData.web.customer_referal_popup_msg;
    okBtn = this.languageData.web.customer_referal_popup_cta;
    const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.data = {
      title: title, subTitle: subTitle, okBtnTxt: okBtn, modalName: 'customerReferalUrl', cancelIcon: false,
    };
    modalRef.componentInstance.customerReferal.subscribe((res) => {
      if (res) {
        this.router.navigateByUrl(('/'));
      }
    })
  }
}
